@use "@shopliftr/common-ng" as common;

/**
* Global theming for this application.
*
* Everything should be prefixed mgd-smart
*/
@mixin application-theme($theme) {

    // These are styles that apply to the PanelEditorItemDirective
    .mgd-smart-panel-editor-item {
        box-sizing: content-box;
        overflow: hidden;
        display: flex;
        cursor: pointer;
        .mgd-smart-design-mode-element {
            display: none;
        }

        .mgd-smart-inherit {
            font-weight: inherit;
            font-style: inherit;
            text-decoration: inherit;
        }
    
        .mgd-smart-text-extender {
    
            position: relative;
            width: fit-content;
            height: fit-content;
            &::before {
                display: flex;
                position: absolute;
                content: attr(data-prefix);
                font-size: var(--prefix-font-size);
                color: var(--prefix-font-color);
                width: var(--prefix-width);
                height: var(--prefix-height);
                flex-direction: var(--prefix-direction);
                justify-content: var(--prefix-justify);
                bottom: var(--prefix-bottom);
                right: var(--prefix-right);
                line-height: var(--prefix-line-height);
                white-space: nowrap;
            }
    
            &::after {
                display: flex;
                position: absolute;
                content: attr(data-suffix);
                font-size: var(--suffix-font-size);
                color: var(--suffix-font-color);
                width: var(--suffix-width);
                height: var(--suffix-height);
                flex-direction: var(--suffix-direction);
                justify-content: var(--suffix-justify);
                top: var(--suffix-top);
                left: var(--suffix-left);
                line-height: var(--suffix-line-height);
                white-space: nowrap;
            }
        }

        &.mgd-smart-draggable {
            cursor: grab;
            &.mgd-smart-dragging {
                cursor: grabbing;
            }
        }
        // This applies to the PanelEditorContainer directive
        &.mgd-smart-panel-editor-container {
            overflow: visible;

        }
        &.mgd-smart-selected {
            z-index: 250;
            outline: 3px dashed rgba($color: #000000, $alpha: 0.4);
            box-shadow: -5px -5px 5px rgba(255, 255, 255, 0.2),
                5px 5px 5px rgba(0, 0, 0, 0.2),
                inset -5px -5px 5px rgba(255, 255, 255, 0.2),
                inset 5px 5px 5px rgba(0, 0, 0, 0.2);
        }
        &.mgd-smart-design-mode {
            background-color: common.$color-background-badge!important;
            border-color: common.$font-color;
            border-width: 1px;
            border-style: dotted;
            overflow: hidden;
            opacity: 0.8;
            // if it is not the Panel Editor Container, hide everything that isn't the design-mode-element
            &:not(.mgd-smart-panel-editor-container)>:not(.mgd-smart-design-mode-element) {
                    display: none;
            }
            .mgd-smart-design-mode-element {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: common.$font-color;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 9px;
            }

            // This applies to the PanelEditorContainerDirective
            &.mgd-smart-panel-editor-container {
                overflow: visible;
                &>.mgd-smart-design-mode-element {
                    color: common.$color-placeholder-text;
                }
            }
        }
        &.mgd-smart-placeholder, &.mgd-smart-design-mode {
            background-color: rgba($color: #fff, $alpha: 0.6);
        }
        &.mgd-smart-error {
            background-color: rgba($color: red, $alpha: 0.6);
            >:not(.mgd-smart-design-mode-element) {
                visibility: hidden;
            }
        }
    }
}