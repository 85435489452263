/**
 * STYLES
 *
 * Main entry for CSS styles used in the application.
 * Refer to the README file for instructions on extending common-ng
 * components and using custom themes with Material Design.
 */


@use "@angular/material" as mat;
@use "@shopliftr/common-ng" as common;
@use "css-fx-layout" as fx;
@use "./smart" as smart;

// theme customization - example 1
//
// $theme-config: common.define-theme-config(mat.define-palette(mat.$teal-palette, 900));
// $theme: common.define-theme($theme-config);

// theme customization - example 2
// note: custom font also needs to be added to index.html as follows:
//       <head>
//       ...
//       <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Sofia">
//       ...
//       </head>
//
// $font-family: 'Kufam', sans-serif;
// $theme-config: common.define-theme-config(mat.define-palette(mat.$green-palette, 900), mat.define-palette(mat.$lime-palette, 700), mat.define-palette(mat.$orange-palette));
// $theme: common.define-theme($theme-config, $font-family);

// bootstrap demo app with default ShopLiftr theme
$theme: common.get-theme();

@include common.all-component-themes($theme);
@include smart.application-theme($theme);


// If you want to include the responsive API selectors
$includeResponsiveApi: true;

// If you choose to use attribute selectors:
@include fx.attribute-selectors($includeResponsiveApi);
@include fx.gap-attribute-selectors(1, 8, 1, px em rem, $includeResponsiveApi);
@include fx.gap-attribute-selectors-from-list(10 32 48, px em rem, $includeResponsiveApi);
@include fx.flex-size-attribute-selectors(5, 100, 5, $includeResponsiveApi);
@include fx.flex-size-attribute-selectors-from-list(2 3 4 6 9 12 17 33 36 42 48 66, $includeResponsiveApi);